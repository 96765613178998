<template>
  <v-data-table
    :headers="headers"
    :items="reconciles"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <!--        <v-btn-->
        <!--          color="primary"-->
        <!--          dark-->
        <!--          class="mb-2"-->
        <!--          @click="adjustAmountProductOnline"-->
        <!--        >-->
        <!--          ปรับจำนวนให้เท่ากับภายใน-->
        <!--        </v-btn>-->
        <v-dialog
          v-model="dialog"
          max-width="700"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <!--            <v-btn-->
            <!--              color="primary"-->
            <!--              dark-->
            <!--              class="mb-2"-->
            <!--              v-bind="attrs"-->
            <!--              v-on="on"-->
            <!--              @click="adjustAmountProductOnline"-->
            <!--            >-->
            <!--              ปรับจำนวนให้เท่ากับภายใน-->
            <!--            </v-btn>-->
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12">
                    <!--                           worn_out_online-->
                    <v-card>
                      <v-card-text>
                        <v-radio-group
                          v-model="editedItem.is_worn_out"
                          label="ของชำรุดเสียหาย">
                          <v-radio value="1" label="ใช่"></v-radio>
                          <v-radio value="0" label="ไม่ใช่"></v-radio>
                        </v-radio-group>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    v-for="(item,index) in editedItem "
                    cols="6"
                    sm="6"
                    md="6">
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12">
                            <h2>สต๊อคสินค้า :
                              {{ item.type_product == 1 ? 'ออนไลน์' : 'ภายใน' }}</h2>
                            <h3>ชื่อสินค้า : {{ item.product_name }}</h3>
                            <p>รหัสสินค้า : {{ item.product_code }} </p>
                          </v-col>
                          <v-col
                            cols="6"
                            sm="6"
                            md="6">
                            <p>รวมต้นทุน</p>
                            <h3>{{ item.product_costs }} บาท</h3>
                          </v-col>
                          <v-col
                            cols="6"
                            sm="6"
                            md="6">
                            <p>จำนวนสินค้าคงเหลือ</p>
                            <h3>{{ item.product_amount }} ชิ้น</h3>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card class="mt-5">
                      <v-card-text>
                        <v-row>
                          <!--                          <v-col-->
                          <!--                            cols="12"-->
                          <!--                            sm="12"-->
                          <!--                            md="12">-->
                          <!--&lt;!&ndash;                           worn_out_online&ndash;&gt;-->
                          <!--                            <v-radio-group-->
                          <!--                              v-model="item.is_worn_out"-->
                          <!--                              label="ของชำรุดเสียหาย">-->
                          <!--                              <v-radio value="1" label="ใช่"></v-radio>-->
                          <!--                              <v-radio value="0" label="ไม่ใช่"></v-radio>-->
                          <!--                            </v-radio-group>-->
                          <!--                          </v-col>-->

                          <v-col
                            cols="12"
                            sm="12"
                            md="12">
                            <v-text-field
                              v-model="item.save_product_amount"
                              type="number"
                              placeholder="จำนวนสินค้า"
                              maxlength="8"
                              v-on:blur="checkAmount(index)"
                              label="เพิ่ม/ลด จำนวนสินค้า"></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="12">
                            <v-text-field
                              readonly
                              v-model="item.product_costs"
                              type="number"
                              placeholder="ทุนต่อหน่วย(บาท)"
                              label="ทุนต่อหน่วย(บาท)"></v-text-field>
                          </v-col>

                          <v-col
                            cols="12"
                            sm="12"
                            md="12">
                            <v-textarea
                              v-model="item.product_amount_comment"
                              placeholder="หมายเหตุ"
                              label="หมายเหตุ"></v-textarea>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                  </v-col>
                  <!--                  <v-col-->
                  <!--                    cols="6"-->
                  <!--                    sm="6"-->
                  <!--                    md="6">-->

                  <!--                    <v-card>-->
                  <!--                      <v-card-text>-->
                  <!--                        <v-row>-->
                  <!--                          <v-col-->
                  <!--                            cols="12"-->
                  <!--                            sm="12"-->
                  <!--                            md="12">-->
                  <!--                            <h3>ชื่อสินค้า : {{editedItem.product_name}}</h3>-->
                  <!--                            <p>รหัสสินค้า : {{editedItem.product_code}} </p>-->
                  <!--                          </v-col>-->
                  <!--                          <v-col-->
                  <!--                            cols="6"-->
                  <!--                            sm="6"-->
                  <!--                            md="6">-->
                  <!--                            <p>รวมต้นทุน</p>-->
                  <!--                            <h3>{{ editedItem.product_costs }} บาท</h3>-->
                  <!--                          </v-col>-->
                  <!--                          <v-col-->
                  <!--                            cols="6"-->
                  <!--                            sm="6"-->
                  <!--                            md="6">-->
                  <!--                            <p>จำนวนสินค้าคงเหลือ</p>-->
                  <!--                            <h3>{{ editedItem ? editedItem.product_amount : 0 }} ชิ้น</h3>-->
                  <!--                          </v-col>-->
                  <!--                        </v-row>-->
                  <!--                      </v-card-text>-->
                  <!--                    </v-card>-->
                  <!--                  </v-col>-->

                  <!--                  <v-col-->
                  <!--                    cols="6"-->
                  <!--                    sm="6"-->
                  <!--                    md="6">-->
                  <!--                    <v-card>-->
                  <!--                      <v-card-text>-->
                  <!--                        <v-row>-->
                  <!--                          <v-col-->
                  <!--                            cols="12"-->
                  <!--                            sm="12"-->
                  <!--                            md="12">-->
                  <!--                            <v-radio-group label="ของชำรุดเสียหาย" v-model="worn_out_online">-->
                  <!--                              <v-radio value="Y" label="ใช่"></v-radio>-->
                  <!--                              <v-radio value="N" label="ไม่ใช่"></v-radio>-->
                  <!--                            </v-radio-group>-->
                  <!--                          </v-col>-->

                  <!--                          <v-col-->
                  <!--                            cols="12"-->
                  <!--                            sm="12"-->
                  <!--                            md="12">-->
                  <!--                            <v-text-field-->
                  <!--                              v-model="editedItem.save_product_amount"-->
                  <!--                              type="number"-->
                  <!--                              placeholder="เพิ่ม/ลด จำนวนสินค้า"-->
                  <!--                              label="เพิ่ม/ลด จำนวนสินค้า"></v-text-field>-->
                  <!--                          </v-col>-->

                  <!--                          <v-col-->
                  <!--                            cols="12"-->
                  <!--                            sm="12"-->
                  <!--                            md="12">-->
                  <!--                            <v-text-field-->
                  <!--                              readonly-->
                  <!--                              v-model="editedItem.product_costs"-->
                  <!--                              type="number"-->
                  <!--                              placeholder="ทุนต่อหน่วย(บาท)"-->
                  <!--                              label="ทุนต่อหน่วย(บาท)"></v-text-field>-->
                  <!--                          </v-col>-->

                  <!--                          <v-col-->
                  <!--                            cols="12"-->
                  <!--                            sm="12"-->
                  <!--                            md="12">-->
                  <!--                            <v-textarea-->
                  <!--                              readonly-->
                  <!--                              v-model="editedItem.product_amount_comment"-->
                  <!--                              placeholder="หมายเหตุ"-->
                  <!--                              label="หมายเหตุ"></v-textarea>-->
                  <!--                          </v-col>-->
                  <!--                        </v-row>-->
                  <!--                      </v-card-text>-->
                  <!--                    </v-card>-->
                  <!--                  </v-col>-->
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              คุณต้องการปรับจำนวนให้เท่ากับภายใน ใช่หรือไม่?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                ยกเลิก
              </v-btn>
              <v-btn
                color="primary"
                @click="adjustConfirm"
              >
                ยืนยัน
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page - 1) * serverOptions.itemsPerPage) + index + 1 }}
    </template>
    <template v-slot:item.actions="{ item }">
      <!--            <v-icon-->
      <!--              small-->
      <!--              class="mr-2"-->
      <!--              @click="editItem(item)"-->
      <!--            >-->
      <!--              -->
      <!--            </v-icon>-->
      <v-btn
        color="secondary"
        @click="editItem(item)"
      >
        <v-icon
          small
        >
          {{ icons.mdiPencil }}
        </v-icon>

        ปรับยอดสินค้า
      </v-btn>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_reconcile from '../../../services/reconcile'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions: {
      page: 1,
      itemsPerPage: 10,
    },
    total: 0,
    reconciles: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      {text: 'รหัสสินค้า', value: 'product_code'},
      {text: 'ยี่ห้อ', value: 'brand.phone_brand_name', sortable: false},
      {text: 'รุ่น', value: 'phone_model.phone_model_name', sortable: false},
      {text: 'จำนวนคงเหลือ(ออนไลน์)/ชิ้น', value: 'product_amount_online'},
      {text: 'จำนวนคงเหลือ(ภายใน)/ชิ้น', value: 'product_amount'},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      product_id: '',
      product_amount: '',
      product_costs_present: '',
      product_amount_comment: [],
      is_worn_out: '0',
      // online
      save_product_amount: [],
      product_costs: '',
      worn_out_online: 'N',
    },
    defaultItem: {
      product_id: '',
      product_amount: '',
      product_costs_present: '',
      product_amount_comment: [],
      is_worn_out: '0',
      // online
      save_product_amount: [],
      product_costs: '',
      worn_out_online: 'N',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'ข้อมูลสรุปสินค้า' : 'ข้อมูลสรุปสินค้า'
    },
  },

  watch: {
    serverOptions: {
      handler() {
        this.getReconcile()
      },
    },
    search: {
      handler() {
        this.getReconcile()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getReconcile()
  },

  methods: {
    checkAmount(index) {
      if (this.editedItem[index].save_product_amount < 0) {
        let split = this.editedItem[index].save_product_amount.split('-')
        if (split[1] > this.editedItem[index].product_amount) {
          this.editedItem[index].validate_error = true;
          this.editedItem[index].save_product_amount = 0;
          this.check_error = true;
        }
      } else {
        this.editedItem[index].validate_error = false;
        this.check_error = false;
      }
    },
    adjustAmountProductOnline() {
      // instance_reconcile.adjustAmountProductOnline().then(res => {
      //
      // }).catch(err => {
      //
      // });

      // this.editedIndex = this.reconciles.indexOf(item)
      // this.editedItem = {...item}
      this.dialogDelete = true
    },
    editItem(item) {
      this.$store.state.isLoading = true
      instance_reconcile.show(item.product_code).then(res => {

        this.editedIndex = this.reconciles.indexOf(item)
        // this.editedItem = {...item}
        this.editedItem = res.data.data
        this.editedItem.is_worn_out = "0"
        this.dialog = true
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })

    },

    deletedeleteItemItem(item) {
      this.editedIndex = this.reconciles.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },

    adjustConfirm() {
      this.$store.state.isLoading = true
      instance_reconcile.adjustAmountProductOnline().then(res => {

        // this.products.splice(this.editedIndex, 1)
        // this.getReconcile();
        this.getReconcile();
        this.closeDelete()
      }).catch(err => {
        this.$store.state.isLoading = false

      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    save() {
      this.$store.state.isLoading = true
      if (this.editedIndex > -1) {
        instance_reconcile.update(this.editedItem)
          .then(res => {
            this.getReconcile()
            this.close()
          }).catch(err => {
          this.$store.state.isLoading = false

        });
      } else {
        instance_reconcile.store(this.editedItem)
          .then(res => {
            this.getReconcile()
            this.close()
          }).catch(err => {
          this.$store.state.isLoading = false
        });
      }
    },
    getReconcile() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size: this.serverOptions.itemsPerPage,
        page: this.serverOptions.page,
        date: '',
        status: '',
        sortBy: this.sortBy ?? "",
        sortDesc: this.sortDesc ?? ''
      }

      instance_reconcile.getReconcile(data).then(res => {
        this.reconciles = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
