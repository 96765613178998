/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getReconcile(data) {

    return api.get(`reconcile/list?search=${data.search}&date=${data.date}&status=${data.status}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  getReconcileReport(data) {

    return api.get(`reconcile/get_product_worn_out?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  adjustAmountProductOnline(){
    return api.post(`reconcile/adjust_amount_product_online`)
  },

  show(code){
    return api.get(`reconcile/show/${code}`)
  },
  employeeTypeStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`employee_type/store`, params, {})
  },

  update(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        if (getFormData[key] && getFormData[key] != undefined) {
          if (key == 'is_worn_out'){
            params.append(key, getFormData[key]);
          }else{
            params.append('product_id[]', getFormData[key].product_id);
            params.append('save_product_amount[]', getFormData[key].save_product_amount);
            params.append('product_amount_comment[]', getFormData[key].product_amount_comment);

          }
          // params.append('worn_out[]', getFormData[key].is_worn_out);
        }
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`reconcile/update/1`, params, {})
  },

  employeeTypeDestroy(data) {
    let getFormData = data

    let params = new FormData();
    // params.append('_method', 'DELETE');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }

    return api.delete(`employee_type/delete/${data.employee_type_id}`, params, {})
  },

}
